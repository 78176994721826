// Here you can add other styles
// Cursor

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

// background
.bg-primary-mint {
    background-color: #f5fffc;
}

.img-spinner {
    width: 150px;
}

.avatar-text{
    color: #00D196;
    width: 100%;
    height: 100%;
    font-weight: 800;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Remove Arrows on input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove Arrows on input number */
input[type=number] {
  -moz-appearance: textfield;
}

.responsive-table-container{
    overflow: auto;
    .table-container {
        min-width: 1000px;
    }
}

.responsive-date-container{
    @media screen and (max-width: 419px) {
        transform: scale(0.7);
    }
}