// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~ngx-toastr/toastr-bs4-alert";
@import "~plyr/dist/plyr.css";

// Mixins
@import "mixins";

// If you want to add something do it here
@import "custom";

// Components
@import "alert";
@import "buttons";
@import "card";
@import "carousel";
@import "custom-forms";
@import "datepicker";
@import "dropdown";
@import "empty";
@import "forms";
@import "images";
@import "modal";
@import "nav";
@import "reboot";
@import "sidebar";
@import "tables";
@import "tooltip";
@import "type";
@import "badge";

// Utility classes
@import "utilities";
