@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Libre+Franklin&display=swap');

// Custom variables

$edu-primary: (
    "default": #00D196,
    "light": #F5FFFC,
    "medium": #CCF6EA,
    "dark": #00AE7D,
    "darker": #00694B
);
$edu-secondary: (
    "default": #FF5C00,
    "light": #FFFCFB,
    "medium": #FFE8DB,
    "dark": #D44D00,
    "darker": #AA3D00
);

$edu-theme-colors: (
    "edu-primary": $edu-primary,
    "edu-secondary": $edu-secondary
);

$font-weight-medium: 500 !default;

// Variable overrides

// Color system

$white:    #ffffff !default;
$gray-100: #F5F5F5 !default;
$gray-200: #EEEEEE !default;
$gray-300: #E2E2E2 !default;
$gray-400: #CFCFCF !default;
$gray-500: #A2A2A2 !default;
$gray-600: #7B7B7B !default;
$gray-700: #676767 !default;
$gray-800: #474747 !default;
$gray-900: #212529 !default;
$black:    #0A0A0A !default;

$primary: #00D196 !default;
$secondary: #FF5C00 !default;
$success: #2CB120 !default;
$info: #2B94F5 !default;
$warning: #E8A010 !default;
$danger: #F4484A !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    "1\\/2": 50%,
    "1\\/3": 33.333333%,
    "2\\/3": 66.666667%,
    "1\\/4": 25%,
    "2\\/4": 50%,
    "3\\/4": 75%,
    "1\\/5": 20%,
    "2\\/5": 40%,
    "3\\/5": 60%,
    "4\\/5": 80%,
    "1\\/6": 16.666667%,
    "2\\/6": 33.333333%,
    "3\\/6": 50%,
    "4\\/6": 66.666667%,
    "5\\/6": 83.333333%,    
    "1\\/12": 8.333333%,
    "2\\/12": 16.666667%,
    "3\\/12": 25%,
    "4\\/12": 33.333333%,
    "5\\/12": 41.666667%,
    "6\\/12": 50%,
    "7\\/12": 58.333333%,
    "8\\/12": 66.666667%,
    "9\\/12": 75%,
    "10\\/12": 83.333333%,
    "11\\/12": 91.666667%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-color: $gray-800 !default;

// Links
//
// Style anchor elements.

$link-color: #00AE7D !default;
$link-hover-color: #00694B !default;
$link-hover-decoration: none !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius: 8px !default;
$border-radius-lg: 8px !default;
$border-radius-sm: 8px !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base: 'DM Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$headings-margin-bottom: 0 !default;
$headings-font-family: 'Libre Franklin', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-weight: 700 !default;
$headings-color: $black !default;

$text-muted: $gray-700 !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-link-disabled-color: $gray-500 !default;

// Forms

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color: $gray-500 !default;
$custom-control-indicator-border-color: $gray-400 !default;
$custom-control-indicator-checked-bg: map-get($edu-secondary, "default") !default;
$custom-control-indicator-checked-border-color: map-get($edu-secondary, "default") !default;
$custom-control-indicator-focus-box-shadow: 0 0 0 .2rem rgba(map-get($edu-secondary, "default"), .2) !default;
$custom-control-indicator-focus-border-color: map-get($edu-secondary, "default") !default;

// Tooltips

$tooltip-opacity: .85 !default;

// Modal
$modal-inner-padding: 16px !default;

$modal-header-padding-y: 16px !default;
$modal-header-padding-x: 16px !default;

// Carousel

$carousel-indicator-width: 10px !default;
$carousel-indicator-height: 10px !default;
$carousel-indicator-active-bg: $gray-300 !default;

// Sidebar

$sidebar-bg: $black !default;

// Sidebar navigation

$sidebar-nav-link-color: $gray-300 !default;
$sidebar-nav-link-icon-color: $gray-300 !default;

$sidebar-nav-link-hover-color: map-get($edu-primary, "default") !default;
$sidebar-nav-link-hover-bg: transparent !default;
$sidebar-nav-link-hover-icon-color: map-get($edu-primary, "default") !default;

$sidebar-nav-link-active-color: $black !default;
$sidebar-nav-link-active-bg: map-get($edu-primary, "default") !default;
$sidebar-nav-link-active-icon-color: $black !default;