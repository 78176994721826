.nav-link {
    color: $sidebar-nav-link-color !important;
    background: $sidebar-nav-link-bg !important;

    .nav-icon {
        vertical-align: middle;
    }

    &.active {
        color: $sidebar-nav-link-active-color !important;
        background: $sidebar-nav-link-active-bg !important;
    }

    &:hover {
        color: $sidebar-nav-link-hover-color !important;
        background: $sidebar-nav-link-hover-bg !important;
    }
}