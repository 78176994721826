@each $color, $value in $edu-theme-colors {
    .btn-#{$color} {
        @include edu-button-variant($value);
    }
}

@each $color, $value in $edu-theme-colors {
    .btn-outline-#{$color} {
        @include edu-button-outline-variant($value);
    }
}